import React, { useState, useEffect } from 'react';
import Login from './components/Login';
import MetarList from './components/MetarList';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // Check if user is logged in when the app first loads
  useEffect(() => {
    const token = localStorage.getItem('jwt');
    if (token) {
      setIsLoggedIn(true); // User is logged in if a token exists
    }
  }, []);

  const handleLogin = () => {
    setIsLoggedIn(true); // Set login state to true
  };

  const handleLogout = () => {
    localStorage.removeItem('jwt'); // Remove JWT from localStorage
    setIsLoggedIn(false); // Set login state to false
  };

  return (
    <div>
      <h1>METAR Viewer</h1>
      {!isLoggedIn ? (
        <Login onLogin={handleLogin} /> // Render login form if not logged in
      ) : (
        <div>
          <MetarList /> {/* Render METAR data if logged in */}
          <button onClick={handleLogout}>Logout</button>
        </div>
      )}
    </div>
  );
};

export default App;
