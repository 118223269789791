import React, { useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';  // Correct import

const MetarList = () => {
  const [metars, setMetars] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);  // Set default to false for regular user

  useEffect(() => {
    // Check the user's role (admin or user)
    const checkAdmin = () => {
      const token = localStorage.getItem('jwt');
      if (token) {
        try {
          const decoded = jwtDecode(token);  // Decode the JWT token
          
          // Check if the role is 'admin' in the correct namespaced claim
          if (decoded['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'] === 'admin') {
            setIsAdmin(true);  // Set isAdmin to true if admin role is found
          } else {
            setIsAdmin(false);  // Set isAdmin to false for regular users
          }
        } catch (error) {
          console.error('Error decoding token:', error);
        }
      }
    };

    checkAdmin();  // Check admin status

    // Fetch METAR data
    const fetchMetarData = async () => {
      try {
        const response = await fetch('http://192.168.1.244:50002/api/Metar/latest', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('jwt')}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setMetars(data);  // Store the fetched data
        } else {
          console.error('Failed to fetch METAR data');
        }
      } catch (error) {
        console.error('Error fetching METAR data:', error);
      } finally {
        setLoading(false);  // Stop the loading spinner once data is fetched
      }
    };

    fetchMetarData();  // Fetch data when component is mounted
  }, []);  // Empty dependency array to fetch data only once when the component mounts

  if (loading) {
    return <div>Loading METAR data...</div>;
  }

  return (
    <div>
      <h3>METAR Data</h3>
      <ul>
        {metars.map((metar, index) => (
          <li key={index}>
            <strong>Station:</strong> {metar.station} <br />
            <strong>Raw Data:</strong> {metar.raw} <br />
            {isAdmin && metar.source && (  // Conditionally render 'Source' for admin users
              <>
                <strong>Source:</strong> {metar.source} <br />
              </>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MetarList;
